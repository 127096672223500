import {
  ClientSideFeatureManagementConfig,
  Environment,
  ExperimentParameter,
  ExperimentParameterFetchResult,
  Feature,
  getStatsigCustomProperties,
  getStatsigCustomUnitIds,
  JsClientSideStatsigSdkWrapper,
  STATSIG_ANONYMOUS_EMAIL_ADDRESS,
  STATSIG_ANONYMOUS_IP_ADDRESS,
  toStatsigTierName,
  User
} from '@airtasker/kmp-feature-management';
import {
  StatsigClient,
  StatsigEnvironment,
  StatsigUser
} from '@statsig/js-client';

const convertEnvironmentToStatsigEnvironment = function (
  environment: Environment
): StatsigEnvironment {
  return {
    tier: toStatsigTierName(environment)
  };
};

const convertUserToStatsigUser = function (user: User): StatsigUser {
  const statsigUser: StatsigUser = {
    userID: user.statsigUserId,
    ip: STATSIG_ANONYMOUS_IP_ADDRESS.get(),
    country: user.statsigCountryCode,
    custom: Object.fromEntries(
      getStatsigCustomProperties(user).asJsReadonlyMapView()
    ),
    customIDs: Object.fromEntries(
      getStatsigCustomUnitIds(user).asJsReadonlyMapView()
    )
  };
  const email = STATSIG_ANONYMOUS_EMAIL_ADDRESS.get();
  if (email) {
    statsigUser.email = email;
  }
  return statsigUser;
};

class StatsigSdkWrapperClient implements JsClientSideStatsigSdkWrapper {
  private apiKey: string;
  private isTesting: boolean;
  client: StatsigClient | null = null;

  constructor(apiKey: string, isTesting: boolean) {
    this.apiKey = apiKey;
    this.isTesting = isTesting;
  }

  initialise(
    config: ClientSideFeatureManagementConfig,
    onSdkInitialised: () => void
  ): User {
    this.client = new StatsigClient(
      this.apiKey,
      convertUserToStatsigUser(config.user),
      {
        environment: convertEnvironmentToStatsigEnvironment(config.environment),
        networkConfig: {
          preventAllNetworkTraffic: this.isTesting
        }
      }
    );
    this.client.initializeAsync().then(() => {
      onSdkInitialised();
    });

    return config.user;
  }

  checkGate(feature: Feature): boolean {
    if (this.client === null) {
      throw new Error('Statsig SDK not initialised');
    }

    return this.client.checkGate(feature.key());
  }

  getNonLayeredExperimentParameterBoolean(
    parameter: ExperimentParameter<boolean>
  ): ExperimentParameterFetchResult<boolean> {
    if (this.client === null) {
      throw new Error('Statsig SDK not initialised');
    }

    const experiment = this.client.getExperiment(
      parameter.experiment.experimentName
    );
    const result = experiment.get<boolean>(
      parameter.parameterName,
      parameter.defaultValue
    );
    const variationName = experiment.groupName;
    if (variationName === null) {
      return new ExperimentParameterFetchResult.UnbucketedResult<boolean>(
        result
      );
    }
    return new ExperimentParameterFetchResult.BucketedResult<boolean>(
      result,
      variationName
    );
  }

  getNonLayeredExperimentParameterInteger(
    parameter: ExperimentParameter<number>
  ): ExperimentParameterFetchResult<number> {
    if (this.client === null) {
      throw new Error('Statsig SDK not initialised');
    }

    const experiment = this.client.getExperiment(
      parameter.experiment.experimentName
    );
    const result = experiment.get<number>(
      parameter.parameterName,
      parameter.defaultValue
    );
    const variationName = experiment.groupName;
    if (variationName === null) {
      return new ExperimentParameterFetchResult.UnbucketedResult<number>(
        result
      );
    }
    return new ExperimentParameterFetchResult.BucketedResult<number>(
      result,
      variationName
    );
  }

  getNonLayeredExperimentParameterString(
    parameter: ExperimentParameter<string | null>
  ): ExperimentParameterFetchResult<string | null> {
    if (this.client === null) {
      throw new Error('Statsig SDK not initialised');
    }

    const experiment = this.client.getExperiment(
      parameter.experiment.experimentName
    );
    const result = experiment.get<string | null>(
      parameter.parameterName,
      parameter.defaultValue
    ) as string | null;
    const variationName = experiment.groupName;
    if (variationName === null) {
      return new ExperimentParameterFetchResult.UnbucketedResult<string | null>(
        result
      );
    }
    return new ExperimentParameterFetchResult.BucketedResult<string | null>(
      result,
      variationName
    );
  }

  setUser(user: User): void {
    if (this.client === null) {
      throw new Error('Statsig SDK not initialised');
    }
    this.client.updateUserSync(convertUserToStatsigUser(user));
  }

  shutdown(): void {
    if (this.client === null) {
      throw new Error('Statsig SDK not initialised');
    }
    this.client.shutdown();
  }
}

export { StatsigSdkWrapperClient };
