import { StatsigClient } from '@statsig/js-client';
import { SessionReplay } from '@statsig/session-replay';

import { publicRuntimeConfig } from 'lib/utils/Environment';

interface Player {
  /**
   * Start a new session replay.
   */
  startSessionReplay(): void;
}

class SessionReplayHandler implements Player {
  sessionReplayEnabled: boolean = false;
  private statsigClient: StatsigClient | null = null;

  constructor(client: StatsigClient) {
    this.statsigClient = client;
    this.sessionReplayEnabled = Boolean(publicRuntimeConfig.NEXT_ENABLE_STATSIG_SESSION_REPLAY);
  }

  startSessionReplay() {
    if (!this.sessionReplayEnabled || !this.statsigClient) {
      console.warn('Session replay is disabled in the current environment.');
      return;
    }
    new SessionReplay(this.statsigClient).forceStartRecording();
  }
}

export  {
  SessionReplayHandler,
  type Player
};
